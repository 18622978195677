import React from "react"
import { graphql } from "gatsby"
import Wrap from "components/Wrap"

import SwitchTheme from "components/SwitchTheme"

export default function Html({ pageBuilder }) {
  const { html } = pageBuilder

  return (
    <SwitchTheme name='default'>
      <Wrap>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </Wrap>
    </SwitchTheme>
  )
}

export const query = graphql`
  fragment PageBuilderHtmlQuery on Craft_PageBuilderHtml {
    html
  }
`
